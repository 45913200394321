$background-color_2: #fff;
$background-color_4: #2563eb;
$color_5: #ffffff;
$border-color_2: #3b82f6;
$font-family_2: "ui-serif", "Georgia", "Cambria", "Times New Roman", "Times","serif";
$background-color_5: #c2fbd7;
$color_7: green;

.table {
  tbody {
    padding: 9px;
    tr {
      border-bottom: 1px solid rgb(214, 215, 216);
      td:first-child {
        text-align: left;
        font-size: x-small;
      }
      td:last-child {
        text-align: right;
        font-size: x-small;
      }
    }
  }
}

.container {
  display: flex !important;
  content: center !important;
  align-items: center !important;
}

.fewerdetails {
  text-align: center;
  font-size: 10px;
  background-color: $background-color_4;
  color: $color_5;
  border-color: $border-color_2;
  border-radius: 6px;
  padding: 4px;
}

.analyzeinfull {
  text-align: center;
  font-size: 10px;
  background-color: $background-color_4;
  color: $color_5;
  border-color: $border-color_2;
  border-radius: 6px;
  padding: 4px;
}

.linkbutton {
  font-family: $font-family_2;
  font-size: 100%;
  padding: 2px;
}

.aChip {
  background-color: $background-color_5;
  box-shadow: rgba(44, 187, 99, 0.2) 0 -25px 18px -14px inset,rgba(44, 187, 99, 0.15) 0 1px 2px, rgba(44, 187, 99, 0.15) 0 2px 4px,rgba(44, 187, 99, 0.15) 0 4px 8px, rgba(44, 187, 99, 0.15) 0 8px 16px,rgba(44, 187, 99, 0.15) 0 16px 32px;
  color: $color_7;
  display: inline-block;
  padding-bottom: 4px;
  margin-left: 5px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  transition-behavior: normal;
}
