.p-tooltip .p-tooltip-arrow {
  background-color: rgb(189, 189, 189);
}

.p-tooltip {
  box-shadow: none !important;
  padding: 2px;
}

.p-tooltip .p-tooltip-right .p-tooltip-arrow {
  border-right-color: #dadada;
}

.p-tooltip .p-tooltip-text {
  color: #5d5858;
  background: #fefefe;
  line-height: 12px;
  font-size: x-small;
}
