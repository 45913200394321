.layout-wrapper {
  min-height: 100vh;
}

.layout-topbar {
  position: fixed;
  height: 4.75rem;
  z-index: 997;
  left: 0;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  background-color: var(--surface-card);
  transition: left 0.2s;
  display: flex;
  align-items: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02), 0 0 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.08);
}

.layout-topbar .layout-topbar-logo {
  display: flex;
  align-items: center;
  color: var(--surface-900);
  font-size: 1.5rem;
  font-weight: 500;
  width: 300px;
  border-radius: 12px;
}

.layout-topbar .layout-topbar-logo img {
  margin-right: 0.5rem;
}

.layout-topbar .layout-topbar-logo:focus {
  outline: 0 none;
  outline-offset: 0;
  transition: box-shadow 0.2s;
  box-shadow: var(--focus-ring);
}

.layout-topbar .layout-topbar-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: var(--text-color-secondary);
  width: 3rem;
  height: 3rem;
}

.layout-topbar .layout-topbar-button:hover {
  color: var(--text-color);
  background-color: var(--surface-hover);
}

.layout-topbar .layout-topbar-button:focus {
  outline: 0 none;
  outline-offset: 0;
  transition: box-shadow 0.2s;
  box-shadow: var(--focus-ring);
}

.layout-topbar .layout-topbar-menu-button {
  display: none;
}

.layout-topbar .layout-topbar-menu-button i {
  font-size: 1.25rem;
}

.layout-topbar .layout-topbar-menu {
  margin: 0 0 0 auto;
  padding: 0;
  list-style: none;
  display: flex;
}

.layout-topbar .layout-topbar-menu .layout-topbar-button {
  margin-left: 1rem;
}

@media (max-width: 991px) {
  .layout-topbar {
    justify-content: space-between;
  }

  .layout-topbar .layout-topbar-logo {
    width: auto;
    order: 2;
  }

  .layout-topbar .layout-menu-button {
    margin-left: 0;
    order: 1;
  }

  .layout-topbar .layout-topbar-menu-button {
    display: inline-flex;
    margin-left: 0;
    order: 3;
  }

  .layout-topbar .layout-topbar-menu {
    margin-left: 0;
    position: absolute;
    flex-direction: column;
    background-color: var(--surface-overlay);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.02), 0 0 2px rgba(0, 0, 0, 0.05), 0 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 1rem;
    right: 2rem;
    top: 5rem;
    min-width: 15rem;
    display: none;
    animation: scalein 0.15s linear;
  }

  .layout-topbar .layout-topbar-menu.layout-topbar-menu-mobile-active {
    display: block;
  }

  .layout-topbar .layout-topbar-menu .layout-topbar-button {
    margin-left: 0;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 1rem;
  }

  .layout-topbar .layout-topbar-menu .layout-topbar-button i {
    font-size: 1rem;
    margin-right: 0.5rem;
  }

  .layout-topbar .layout-topbar-menu .layout-topbar-button span {
    font-weight: medium;
    display: block;
  }
}

.layout-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.layout-menu .layout-root-menuitem > .layout-menuitem-root-text {
  font-size: 0.857rem;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--surface-900);
  margin: 0.75rem 0;
}

.layout-menu .layout-root-menuitem > a {
  display: none;
}

.layout-menu li.active-menuitem > a .layout-submenu-toggler {
  transform: rotate(-180deg);
}

.layout-menu li.active-menuitem > ul {
  max-height: auto;
}

.layout-menu ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.layout-menu ul a {
  display: flex;
  align-items: center;
  position: relative;
  outline: 0 none;
  color: var(--text-color);
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 12px;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.layout-menu ul .active-route a span {
  font-weight: 400;
  color: var(--primary-color);
}

.layout-config-sidebar.p-sidebar .p-sidebar-content {
  padding-left: 2rem;
  padding-right: 2rem;
}

.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  padding: 5rem 4rem 2rem 4rem;
  transition: margin-left 0.2s;
}

.layout-main {
  flex: 1 1 auto;
}

.layout-footer {
  transition: margin-left 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  border-top: 1px solid var(--surface-border);
}

@media screen and (min-width: 1960px) {
  .landing-wrapper,
  .layout-main {
    width: 1504px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 1024px) {
  .blocked-scroll {
    overflow: hidden;
  }
}
