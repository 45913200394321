.login {
  background-image: url("https://cdn2.hubspot.net/hubfs/435410/promembershiptools/images/backgrounds/perth_landscape_bg.jpg");
  background-attachment: fixed;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Australia Map Controller */
.au-map__state {
  fill: rgb(75, 72, 76);
}

path.au-map__state {
  &.vic {
    fill: #656067;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.wa {
    fill: #3e3b3f;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.nt {
    fill: #4b484c;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.sa {
    fill: #585459;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.qld {
    fill: #716d74;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.nsw {
    fill: #7e7981;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.act {
    fill: #3e3b3f;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.tas {
    fill: #8b868d;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
}

/* newzealand Map Controller */
path.nz-map__state {
  &.nor {
    fill: #3e3b3f;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.auc {
    fill: #4b484c;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.wai {
    fill: #464347;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.bay {
    fill: #524f54;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.gis {
    fill: #746f76;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.tar {
    fill: #5f5b61;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.haw {
    fill: #4b484c;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.man {
    fill: #4b484c;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.mar {
    fill: #3e3b3f;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.nel {
    fill: #464347;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.tas {
    fill: #434044;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.wel {
    fill: #3e3b3f;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.wes {
    fill: #555257;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.can {
    fill: #5f5b61;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.sou {
    fill: #3e3b3f;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
  &.ota {
    fill: #6a656c;
    &:hover,
    &.selected {
      fill: #b91308;
    }
  }
}
/* Custom Styles */

.map-toggle .p-button {
  border-radius: 0;
}

.edit-saved-search .p-dropdown-label {
  font-size: 11px;
}

.hide-datatable-header .p-datatable-thead {
  display: none;
}

.edit-saved-search .p-dropdown-label {
  font-size: 11px;
}

.edit-saved-search .p-divider {
  margin: 0.5rem 0;
}

.transperent-loader {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.transperent-loader:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}

.p-chart {
  display: flex;
  justify-content: center;
}

.p-chart canvas {
  height: 172px !important;
}

.payment-panel .p-panel-content {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.p-panel .p-panel-header {
  padding: 0.5em 0 0.75em 1.25em;
}

#scrollable-content::-webkit-scrollbar {
  display: none;
}

#scrollable-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.align-center-vl {
  text-align: -webkit-center;
}

.update-statistics-dialog .p-inputnumber-input {
  display: inline-block;
  width: 100%;
}

.edit-default-profile .p-inputnumber-input {
  width: 60%;
}

.p-dialog-title {
  color: var(--primary-color);
}

.summary-input-panel .p-panel-content {
  padding: 0;
}

.pa-stepper-header .p-tabview .p-tabview-nav li .p-tabview-nav-link {
  padding: 0.8rem;
}

.basicFileUpload .p-fileupload-choose {
  background-color: var(--primary-color);
  color: white;
  border-radius: 5px;
}

.image-gallery-container {
  text-align: left;
  background: #ffffff;
}

.reigallary {
  position: relative;
}

.reigallary .p-button-danger {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.justified-between-tabview .p-tabview-nav {
  justify-content: space-evenly;
}

.higlish-selected-tabview .p-tabview-selected {
  background-color: rgb(198, 198, 198);
  font-size: large;
  color: whitesmoke;
}

.hide-empty-message .p-datatable-emptymessage {
  display: none !important;
}
