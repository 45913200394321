$color_1: #fff;
$color_2: #222;
$color_3: #e0e0e0;
$color_4: #4e9fba;
$color_5: #ffffff;
$color_6: #4b5563;
$color_7: green;
$font-family_1: "Open Sans", "Monda", "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
$font-family_2: "ui-serif", "Georgia", "Cambria", "Times New Roman", "Times", "serif";
$background-color_1: #525459;
$background-color_2: #fff;
$background-color_3: #d1d5db;
$background-color_4: #2563eb;
$background-color_5: #c2fbd7;
$border-color_1: none;
$border-color_2: #3b82f6;
$color_8: rgba(255, 255, 255, 0.1);
$background-color_1: #222;

.main-section {
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
  max-width: 92.30769em;
  margin-top: 50px;
}

.custom-panel {
  div {
    div {
      box-shadow: rgba(127, 125, 128, 0.35) 0 0.15em 0.35em;
      background-color: $background-color_2;
      background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
    }
  }
}

.p-panel-content {
  padding: 10px;
}

.p-dialog-header {
  padding: 0.5em 0 0.5em 1.25em;
  font-size: 1em;
}

label {
  font-size: 1em;
}

.panel-box {
  .p-component {
    font-size: x-small !important;
    padding: 3px;
  }
}

.datagrid {
  font-size: x-small !important;
  line-height: 0.5rem !important;
  padding: 6px !important;
  overflow-y: scroll !important;
}

.property-detail-child {
  align-content: center;
}

.p-card-body {
  padding: 10px;
}

.p-card-title {
  font-size: large;
  font-size: small;
}

.p-card {
  .p-card-title {
    margin-bottom: 0;
  }
  background: var(--surface-card) !important;
  border-radius: 6px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.card {
  background: var(--surface-card) !important;
  border-radius: 6px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: $color_6;
  background: #fff;
  border-radius: 6px;
  padding: 5px;
}

.mapCard {
  max-height: 180px;
}

.cardheader {
  border-radius: 6px !important;
  box-shadow: 0px 25px 20px -20px rgba(245, 158, 27, 0.45), -25px 0 20px -20px rgba(245, 158, 27, 0.45);
  margin-top: 10px;
  color: $color_6;
  border-radius: 6px;
  padding: 5px;
  margin-left: 8px;
  margin-right: 8px;
  align-items: center;
}

.p {
  font-size: x-small;
  color: #222 !important;
}

.ql-toolbar.ql-snow {
  padding: 1px !important;
}

.ql-snow {
  .ql-toolbar {
    button {
      padding: 5px 5px;
    }
  }
}

.p-divider {
  padding: 4px !important;
}

@media screen and (min-width: 1024px) {
  .a-chip {
    &:hover {
      box-shadow: rgba(44, 187, 99, 0.35) 0 -25px 18px -14px inset, rgba(44, 187, 99, 0.25) 0 1px 2px,
        rgba(44, 187, 99, 0.25) 0 2px 4px, rgba(44, 187, 99, 0.25) 0 4px 8px, rgba(44, 187, 99, 0.25) 0 8px 16px,
        rgba(44, 187, 99, 0.25) 0 16px 32px !important;
      transform: scale(1.05) !important;
    }
  }
}

.default-profile-header-container {
  color: #6366f1;
  background-color: #fff;
  /* border: 1px solid #ddd; */
  /* border-radius: 8px; */
  padding: 0 0.5rem;
  position: absolute;
  top: -10px;
  font-weight: bold;
}
