//@tailwind base;
//@tailwind components;
@tailwind utilities;

html {
    font-size: 14px;
}

body {
    margin: 0px;
    font-family: var(--font-family);
    font-weight: normal;
    background: var(--surface-ground);
    color: var(--text-color);
    // padding: 0.5rem;
}
